<template>
    <div>
        <p class=" mb-6">Movimientos agrupados por tipo transaccion</p>

        <v-card>
            <v-card-subtitle>Filtros</v-card-subtitle>
            <v-card-text>
                <v-row>

                    <v-col cols="4">
                        <dos-fechas
                            :fechas="[filtros.fecha.desde,filtros.fecha.hasta]"
                            @getFechas="getFechas" :icon="false"></dos-fechas>
                    </v-col>
                    <v-col cols="4">
                        <AreaSelect :clearable="true" v-model="filtros.idArea" :dense="true" :outlined="true"></AreaSelect>
                    </v-col>
                    <v-col cols="4">
                        <v-text-field hide-details="auto" v-model="filtros.barra" label="Barra Producto" outlined
                            dense></v-text-field>
                    </v-col>
                    <v-col cols="4">
                        <v-text-field hide-details="auto" v-model="filtros.nombreProducto" label="Nombre Producto" outlined
                            dense></v-text-field>
                    </v-col>
                    <v-col cols="4">
                        <v-text-field hide-details="auto" v-model="filtros.numeroLote" label="Numero Lote" outlined
                            dense></v-text-field>
                    </v-col>
                    <v-col cols="12" class="text-right">
                        <v-btn :loading="cargando" :disabled="cargando || !validar()" color="primary"
                            @click="buscarDatos()">
                            Buscar
                        </v-btn>
                    </v-col>
                </v-row>

            </v-card-text>
            <v-divider></v-divider>
            <v-card-subtitle>Resultado</v-card-subtitle>
            <v-card-text>
                <tabla-reporte @GetVer="getVer" :acciones="['VER']" :nombreReporte="nombreReporte" :cabeceraIn="cabecera"
                    :datosIn="datos" :exportar="['EXCEL', 'PDF']"></tabla-reporte>

            </v-card-text>
        </v-card>
        
        <ModalMovimientoDetallado ref="ModalMovimientoDetalladoRef"></ModalMovimientoDetallado>
    </div>
</template>
    
<script>
// eslint-disable-next-line object-curly-newline
import { mdiPoll, mdiLabelVariantOutline, mdiCurrencyUsd, mdiHelpCircleOutline } from '@mdi/js'
import TablaReporte from '@/components/TablaReporte.vue';
import { ref } from '@vue/composition-api';
import MovimientoServices from '@/api/servicios/MovimientoServices';
import store from '@/store';
import AreaSelect from '../../archivo/area/componentes/AreaSelect.vue';
import DosFechas from '@/components/DosFechas.vue';
import FuncionesGenerales from '@/funciones/funciones'
import moment from 'moment';
import ModalMovimientoDetallado from '../movimiento-detallado/componentes/ModalMovimientoDetallado.vue'
// demos

export default {
    components: { TablaReporte, DosFechas, AreaSelect,ModalMovimientoDetallado },
    setup() {
        const filtros = ref({

            fecha: {
                desde: moment().format('YYYY-MM-DD'),
                hasta: moment().format('YYYY-MM-DD')
            },
            idArea: null,
            barra: '',
            nombreProducto: '',
            numeroLote: '',
            desde: null,
            hasta: null,
            
        })


        const nombreReporte = ref(`Reporte movimientos agrupados ${store.state.sucursalSelect.nombre} ${filtros.value.fecha.desde} ${filtros.value.fecha.hasta} ${ moment().format('YYYYMMDD h:mm:ss a')}`)
        const cabecera = ref([
            { text: 'Sucursal', value: 'area.sucursal.nombre', width: '130px', visible: true },
            { text: 'Area', value: 'area.nombre', width: '130px', visible: true },
            { text: 'Barra', value: 'productoSucursal.producto.barra', format: { small: true }, visible: true },
            { text: 'Producto', value: 'productoSucursal.producto.nombre', width: '200px', visible: true },
            { text: 'Lote', value: 'lote.numeroLote', width: '130px', visible: true },
            { text: 'Exis Ini', value: 'existenciaInicial', width: '130px', align: 'right', visible: true }, 
            { text: 'Compras', value: 'compras', width: '130px', align: 'right', visible: true }, 
            { text: 'Ventas', value: 'ventas', width: '130px', align: 'right', visible: true }, 
            { text: 'Ajustes', value: 'ajustes', width: '130px', align: 'right', visible: true }, 
            { text: 'Fabricacion', value: 'fabricacion', width: '130px', align: 'right', visible: true }, 
            { text: 'Nota Entrada', value: 'notaEntrada', width: '130px', align: 'right', visible: true }, 
            { text: 'Nota Salida', value: 'notaSalida', width: '130px', align: 'right', visible: true }, 
            { text: 'Transformacion', value: 'transformacion', width: '130px', align: 'right', visible: true }, 
            { text: 'Translado', value: 'transladoInterno', width: '130px', align: 'right', visible: true },  
            { text: 'Entradas', value: 'entradas', width: '130px', align: 'right', visible: true }, 
            { text: 'Salidas', value: 'salidas', width: '130px', align: 'right', visible: true }, 
            { text: 'Total', value: 'existencia', width: '130px', align: 'right', visible: true }, 
            { text: 'Exis Fin', value: 'existenciaFinal', width: '130px', align: 'right', visible: true },  
            { text: 'Inv Actual (' + moment().format('YYYYMMDD')+')', value: 'inventarioActual', width: '130px', align: 'right', visible: true },  
            { text: 'Gen Movimiento', value: 'indMovimiento', width: '130px', align: 'right', visible: true }, 
            { text: 'Utimo Movimiento', value: 'ultimoMovimiento', width: '130px',format:{dateTime:true}, align: 'right', visible: true }, 
            { text: 'Utima Venta', value: 'ultimaVenta', width: '130px', format:{dateTime:true},align: 'right', visible: true }, 
            
            { text: 'Desde', value: 'desde', width: '130px', format:{date:true}, align: 'right', visible: true },  
            { text: 'Hasta', value: 'hasta', width: '130px',   format:{date:true}, align: 'right', visible: true },  
        ])
        const datos = ref(
            [
            ]
        )
        const cargando = ref(false)

        const ModalMovimientoDetalladoRef = ref()


        const validar = () => {
            let validado = true

            return validado
        }

        const buscarDatos = () => {
            cargando.value = true

            MovimientoServices.ConsultarMovimientoProductoReporte(
                {
                    ...
                    filtros.value,
                    desde: FuncionesGenerales.formatoFecha(filtros.value.fecha.desde, 5),
                    hasta: FuncionesGenerales.formatoFecha(filtros.value.fecha.hasta, 5),
                    idSucursal : store.state.sucursalSelect.id
                })
                .then(response => {
                    if (response.data.estatus = true) {
                        datos.value = response.data.datos
                    } else {
                        store.commit('setAlert', {
                            message: response.data.mensaje,
                            type: 'error',
                        })
                    }

                })
                .catch(err => {
                    console.error(err)
                })
                .finally(() => {
                    cargando.value = false

                })
        }

        const getVer = (dato) => {
            console.log(dato)
            ModalMovimientoDetalladoRef.value.abrir({
                fecha : filtros.value.fecha,
                idProductoSucursal: dato.idProductoSucursal,
                idArea: dato.idArea
            })
        }

        const getFechas = (datos) => {
            console.log(datos)
            filtros.value.fecha.desde = datos.desde
            filtros.value.fecha.hasta = datos.hasta
        }


        return {
            cabecera,
            datos,
            validar,
            buscarDatos,
            cargando,
            nombreReporte,
            getVer,
            filtros,
            FuncionesGenerales,
            getFechas,
            ModalMovimientoDetalladoRef,
            moment
        }
    },
}
</script>
    