<template>
    <v-dialog v-model="dialog" max-width="1000px">
        <v-card  style="overflow: hidden;">
  
            <MovimientoDetallado ref="MovimientoDetalladoRef" :mostrarFiltro="false"> </MovimientoDetallado>
        </v-card>
   
    </v-dialog>
</template>

<script>
import { ref } from '@vue/composition-api';
import MovimientoDetallado from '../MovimientoDetallado.vue'
export default {
    components: {
        MovimientoDetallado,
    },
    setup(props, context) {
        const dialog = ref(false)
        const MovimientoDetalladoRef = ref(null)
        const abrir = (datos) => {
            dialog.value = true
            console.log("datos")
            console.log(datos)
            MovimientoDetalladoRef.value.buscarDatosExterno(datos)
        }

        return {
            dialog,
            abrir,
            MovimientoDetalladoRef,
        }
    },
}
</script>
